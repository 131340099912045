@import './variables.scss';

.ant-tabs {
  &-tab {
    transition: all ease 0.3s;
    margin: 0;

    .ant-tabs-tab-btn {
      transition: all ease 0.05s;
    }

    &:hover {
      background: $primary-color;
      color: $light;

      .ant-tabs-tab-btn {
        &:focus {
          color: $light;
        }
      }

      .ant-tabs-tab-title {
        color: $light;
      }
    }

    &-active {
      .ant-tabs-tab-title {
        color: $light;
      }
    }

    &-title {
      color: $dark-900;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
