@import './variables';
body {
  .ant-pagination {
    &-prev,
    &-next,
    &-item,
    &-jump-next,
    &-jump-prev {
      border: 1px solid $light-300;
      border-radius: 0;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $light;
      border-right-width: 0;
      width: 40px;
      height: 40px;

      &-link {
        width: 40px;
      }

      &:hover,
      &:focus {
        border: 1px solid rgba($color: $light, $alpha: 0.1);
        background-color: $primary-color;
        border-right-width: 0;

        a,
        .ant-pagination-item-container .ant-pagination-item-link-icon,
        .ant-pagination-item-container .ant-pagination-item-ellipsis,
        .ant-pagination-item-link {
          color: $light;
        }
      }
    }

    &-prev {
      border-right-width: 0;
      border-top-left-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
    }

    &-next {
      border-right-width: 1px;
      border-top-right-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;

      &:hover {
        border-right-width: 1px;
      }
    }

    &-next,
    &-prev {
      .ant-pagination-item-link {
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0;
        background-color: transparent;
      }
    }

    &-item {
      transition: all ease 0.2s;

      &-active {
        border: 1px solid rgba($color: $primary-color, $alpha: 0.5);
        background-color: $primary-color;

        a {
          color: $light !important;
        }

        &:hover {
          border: 1px solid rgba($color: $primary-color, $alpha: 0.5);
          background-color: $primary-color;

          a,
          span {
            color: $light;
          }
        }
      }
    }
  }
}
