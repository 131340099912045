$primary-color: #297fb9;
$primary-600: #2c91cd;
$info-color: #2b6ed2;

$success-color: #4dc67d;
$success-50: #e6f7ec;
$success-800: #008844;

$danger-900: #bc000d;
$danger-600: #ef2f2f;

$error-color: #f85254;
$error-50: #ffebee;
$error-800: #d01a22;

$warning-color: #e69a1b;
$warning-900: #e36f12;

$border-radius-base: 8px;
$border-radius-xl: 16px;

$light: #fff;
$light-5: #f0f0f0;
$light-6: #fcf7e2;
$light-50: #e3f1fe;
$light-100: #fbfbfb;
$light-200: #fafafa;
$light-300: #ecedee;
$light-400: #e5e5e5;
$light-500: #e3f6fd;
$light-700: #adb6be;

$dark: #000;
$dark-100: #ced3d7;
$dark-5: #979797;
$dark-6: #738492;
$dark-300: #5b7080;
$dark-400: #738492;
$dark-900: #20282e;

$blue: #2b6ed2;
$blue-500: #e3f6fd;

$red: #d70c24;
$red-100: #bc000d;

$shadow-1: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);

$notification-hover: #eff9ff;
$notification-icon-hover: #2980b9;
