@import './variables';
@import './forms';
@import './tables';
@import './pagination';
@import './tabs';

.crm-layout {
  .ant-layout-sider-children {
    min-height: 100vh;
  }
  .ant-menu {
    background-color: $primary-color;
    padding-top: 15px;
  }

  .ant-input-affix-wrapper {
    min-height: 50px;
    width: 400px;
    box-shadow: $shadow-1;

    &-lg {
      padding: 12px 20px;
    }
  }

  .ant-input-prefix {
    font-size: 17px;
    color: $dark-400;
    margin-right: 20px;
  }

  .ant-select-auto-complete {
    margin-left: 110px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    font-size: 14px;
  }

  .ant-layout-header {
    background-color: $primary-color;
  }

  .ant-menu-item {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
  .ant-menu-submenu {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    &-arrow {
      color: white;
    }
  }

  .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: #fff !important;
  }

  .ant-layout-sider-dark {
    background-color: $primary-color;
  }

  .expand-icon {
    right: 15px;
    position: relative;
    width: 32px;
    cursor: pointer;
    transition: transform 0.2s linear;
  }

  .site-layout-sub-header-background {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 44px 0 0 !important;
    height: 112px !important;

    .profile-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .divider {
        width: 2px;
        background-color: #c7c7c7;
        margin: 0 30px;
        height: 30px;
      }
    }

    .menu-title {
      justify-content: center;
      align-items: center;
      margin: 0;
      padding-left: 23px;
      font-size: 24px;
    }
  }
}

.dropdown-profile {
  .ant-divider-horizontal {
    margin: 0px !important;
  }

  .ant-dropdown-menu-item {
    padding: 10px;
  }

  .expand-icon {
    right: 15px;
    position: relative;
    font-size: 32px;
    width: 40px;
    height: 40px;
  }
}

.nav-header {
  border-bottom: 0.5px solid #efefef;
  &__left {
    display: flex;
    align-items: center;
  }
}

.ant-layout {
  &-content-main {
    background-color: $light-200;
    padding: 24px;
    min-height: calc(100vh - 114px);
  }
  .btn {
    &-outline {
      border-color: $primary-color;
      color: $primary-color;
      &:hover {
        color: $light;
        background-color: $primary-color;
      }
    }
  }
}
