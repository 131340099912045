@import './variables';

input::placeholder {
  color: $dark-400 !important;
}

.ant-btn {
  height: 40px;
}

.ant-checkbox-inner {
  border-radius: 2px;
}

.ant-select {
  text-align: left;
  .ant-select-selection-placeholder {
    color: $dark-400;
  }
}

.ant-picker-range {
  .ant-picker-separator {
    .anticon {
      display: none;
    }

    width: 1px;
    background-color: $dark-400;
  }
}
