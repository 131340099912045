@import './variables.scss';

.ant-table {
  background-color: $light;
  border-radius: $border-radius-base;
  overflow: hidden;
  border: 1px solid $light-300;

  tr {
    th,
    td {
      &.ant-table-selection-column {
        padding-left: 16px;
        padding-right: 16px;
        min-width: 100px;
      }
    }

    th {
      background-color: $light;

      &.ant-table-column-has-sorters:hover {
        background: $light-100;
      }
    }
  }

  .table-cell {
    &-export,
    &-setting {
      font-size: 18px;
    }

    &-export {
      color: $primary-color;
    }
  }
}
