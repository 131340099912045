@import './variables';

.notification-drawer {
  .ant-drawer {
    &-title {
      font-size: 22px;
    }
    &-header {
      border-bottom: unset;
    }
  }
  &-no-data {
    .ant-drawer {
      &-body {
        overflow-y: hidden !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-content-wrapper {
        height: 130px;
      }
    }
  }
}
.notification {
  &-item {
    background-color: transparent;
    border: 1px solid rgba(151, 151, 151, 0.202852);
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    &-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
      &-icon {
        color: $dark-100;
        font-size: 16px;
      }
      &-date {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #757575;
      }
    }
    &-desc {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #616161;
    }
  }
  &-active {
    background-color: $notification-hover;
    cursor: pointer;
    .notification-item-info-icon {
      color: $notification-icon-hover;
    }
  }
  &-mark-as-done {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #697684;
    &:hover {
      cursor: pointer;
    }
  }
}
