@import './variables';

.sidebar {
  &__logo {
    display: flex;
    align-items: center;
    padding-left: 32px;
    height: 112px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    img {
      margin-right: 6px;
    }

    span {
      color: $light;
      font-weight: bold;
      font-size: 18px;
    }
  }

  &__menu {
    &-parent {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      min-height: 48px;
      margin-bottom: unset !important;
      img {
        position: absolute;
        min-width: 24px;
        min-height: 24px;
      }
      &-icon {
        font-size: 24px;
        position: absolute;
      }

      > span:not(.anticon) {
        color: $light;
        white-space: normal;
        line-height: 24px;
        &:hover {
          color: $light;
        }
      }
    }
    .grandsubmenu {
      display: list-item;
      list-style-type: none;
      padding-inline-start: 20px;
    }
    &-subparent,
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      padding: 12px 32px;
      height: 51px;
      line-height: 51px;
      position: relative;

      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: $light !important;
      }
      img {
        position: absolute;
        min-width: 24px;
        min-height: 24px;
      }
      &-icon {
        font-size: 24px;
        position: absolute;
      }

      > span:not(.anticon) {
        margin-left: 10px;
        color: $light;
        &:hover {
          color: $light;
        }
      }
    }
    .ant-menu-item-selected {
      .grandsubmenu {
        display: list-item;
        list-style-type: disc;
        padding-inline-start: 0;
      }
      span {
        color: $light !important;
      }
      &::before {
        content: '';
        width: 3px;
        background-color: $light-500;
        position: absolute;
        left: 0;
        height: 100%;
      }
    }
    .ant-menu-submenu-open {
      background-color: #4e97c7;
      .ant-menu-submenu-selected {
        span {
          color: $light;
          margin: 0 0 0 0px;
        }
      }
      .ant-menu-sub {
        background-color: #4e97c7;
        padding: 0;
        .ant-menu-submenu-title,
        .ant-menu-title-content {
          height: 29px;
          line-height: 29px;
          margin: 0 0 0 0 !important;
          span {
            font-size: 14px;
            font-weight: 700;
            color: #e3f1fe;
            margin: 0 0 0 0px;
          }
        }
        .ant-menu-item-only-child,
        .ant-menu-title-content {
          display: flex;
          align-items: center;
          height: 29px;
          line-height: 29px;
          position: relative;
          // margin: 0 0 0 0 !important;
          span {
            font-size: 14px;
            font-weight: 700;
            color: #e3f1fe;
            margin: 0 0 0 20px;
          }
        }
      }
    }
  }
}

.crm-layout-collapse {
  .expand-icon {
    transform: scaleX(-1);
  }

  .sidebar {
    &__logo {
      padding-left: 0;
      justify-content: center;

      img {
        margin-right: 0;
      }

      > span {
        width: 0;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
