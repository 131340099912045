@import './variables';

.ant-notification {
  .success {
    background-color: $success-color;
  }

  .info {
    background-color: $info-color;
  }

  .warning {
    background-color: $warning-color;
  }

  .error {
    background-color: $error-color;
  }
}

.ui-toast {
  border-radius: 8px;

  .ant-notification-notice-message {
    display: flex;
    align-items: center;
  }

  &.ant-notification-notice {
    &-success {
      background-color: $success-color;
    }

    &-info {
      background-color: $info-color;
    }

    &-warning {
      background-color: $warning-color;
    }

    &-error {
      background-color: $error-color;
    }
  }

  .ant-notification-notice {
    &-icon,
    &-message,
    &-close {
      color: #fff;
    }

    &-message {
      margin-bottom: 0;
    }

    &-close {
      .anticon-close {
        font-size: 16px;
      }
    }
  }
}
